import React from "react";
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import SignUp from "~/LocalComponents/Pages/SignUp/SignUp";

import { DataContext, endpoint, token } from "~/Context";
import QueryHTTP from "~/Components/api/QueryHTTP";
const provider = new QueryHTTP(endpoint, token);
// import { DataContext } from "~/Context";
// import data from "./signup.json";
// import QueryJSON from "~/Components/api/QueryJSON";
// const provider = new QueryJSON(data);

const id = "0";

export default function Page({data}) {
  return (
    <DataContext.Provider value={provider}>
      <Helmet htmlAttributes={{lang: "en",}}>
        <title>{data.site.siteMetadata.title}</title>
        {/* <meta charSet="utf-8" /> */}
        <meta name="description" content={data.site.siteMetadata.description} />
        {/* <link rel="canonical" href="https://analyse2activate.com/" /> */}
      </Helmet>
      <SignUp id={id} />
    </DataContext.Provider>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
